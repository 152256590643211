import { Enemy } from './../../../modules/admin/apps/enemy/enemy.types';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import {
  Component,
  Input,
  OnInit,
  ChangeDetectorRef,
  EventEmitter,
  Output,
} from '@angular/core';
import { FuseConfirmationConfig } from '../../../../@fuse/services/confirmation/confirmation.types';
import {
  TrimValidator,
  CustomRequired,
  NumberOnlyValidator,
} from '../../../utils/validators/custom.validator';
import {
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { cloneDeep, filter, omit } from 'lodash';
import { takeUntil, debounceTime, switchMap, Subject } from 'rxjs';

export interface iDynamicForm {
  action: DYNAMIC_FORM_ACTION;
  readonly: boolean;
  serviceName: any;
  createOneMethod: string;
  updateOneMethod: string;
  updateBulkMethod: string;
  patchValue?: any;
  excludes: string[];
  mainTableUiRows: iDynamicFormRow[];
  relationArrays: iDynamicFormRelationArrays[];
}

export interface iDynamicFormRow {
  title: string;
  blockInputs: iDynamicFormEach[];
}

export interface iDynamicFormRelationArrays {
  relationTable: string;
  title: string;
  maxSize: number;
  canAddNew: boolean;
  relationUiRows: iDynamicFormRelationRow[];
}

export interface iDynamicFormRelationRow {
  title: string;
  blockInputs: iDynamicFormEach[];
}

export interface iDynamicFormEach {
  size: string;
  label: string;
  name: string;
  nestedDataKey: string | null;
  type: DYNAMIC_FORM_INPUT;
  initValue: any | null;
  readonly: boolean;
  hidden: boolean;
  hiddenOnCreate: boolean;
  disabledOnCreate: boolean;
  hiddenOnUpdate: boolean;
  disabledOnUpdate: boolean;
  optionSelectors: any[];
  validators: iDynamicFormValidators;
  searchSelectorMetas?: searchSelectorMetas;
  infinityScrollMetas?: infinityScrollMetas;
}

export interface searchSelectorMetas {
  optionValueKey: string;
  searchBoxPlacehoder: string;
  searchByKey: string[];
  optionDisplayKey: string;
  optionDisplayKey2?: string;
}
export interface infinityScrollMetas {
  dataService: any;
  optionValueKey: string;
  searchBoxPlacehoder: string;
  searchByKey: string[];
  optionDisplayKey: string;
  optionDisplayKey2?: string;
}

export interface iDynamicFormValidators {
  required?: boolean;
  min?: any;
  max?: number;
  requiredTrue?: boolean;
  email?: boolean;
  minLength?: number;
  maxLength?: number;
  pattern?: string;
  nullValidator?: boolean;
  trimValidator?: boolean;
  customRequired?: boolean;
  numberOnlyValidator?: boolean;
}

export enum DYNAMIC_FORM_ACTION {
  CREATE = 'Create',
  UPDATE = 'Update',
}

export enum DYNAMIC_FORM_INPUT {
  text = 'text',
  num = 'number',
  password = 'password',
  textarea = 'textarea',
  checkbox = 'checkbox',
  toggle = 'toggle',
  bool = 'boolean',
  searchInput = 'searchInput',
  select_enum = 'select_enum',
  select_search = 'select_search',
  select_all = 'select_all',
  select_infinity = 'select_infinity',
  trigger_list = 'trigger_list',
  datepicker = 'datepicker',
}

@Component({
  selector: 'app-mat-form-generate',
  templateUrl: './mat-form-generate.component.html',
  styleUrls: ['./mat-form-generate.component.scss'],
})
export class MatFormGenerateComponent implements OnInit {
  @Input() dynamicForm: iDynamicForm;
  @Output() formDataEmitter = new EventEmitter<any>();

  public universalForm: UntypedFormGroup = this.formBuilder.group({});

  DYNAMIC_FORM_ACTION = DYNAMIC_FORM_ACTION;
  lastCheckTimestamp = 0;
  triggeredByIDs: string[];

  datas: Enemy[] = [];
  // searchInputControl: UntypedFormControl = new UntypedFormControl();

  // currentSelectedValue: any;
  // enemySelected: {
  //   id: string;
  //   enemyCode: string;
  //   displayName_en?: string;
  // } = null;

  blockPages: { [key: string]: number } = {};
  blockDatas: { [key: string]: any[] } = {};
  blockSelected: {
    [key: string]: { id: string; displayVal1: string; displayVal2: string };
  } = {};
  blockSearchInputs: { [key: string]: UntypedFormControl } = {};

  // searchInputControl: { [key: string]: FormControl } = {};

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private _changeDetectorRef: ChangeDetectorRef,
    private _fuseConfirmationService: FuseConfirmationService // private enemyService: EnemyService<Enemy>
  ) {}

  ngOnInit(): void {
    this.generateDynamicForm(this.dynamicForm, this.dynamicForm.patchValue);
  }

  onClickCancel(): void {
    return;
  }

  submit(): void {
    // Open the confirmation dialog
    const confirmation = this._fuseConfirmationService.open({
      title: `${this.dynamicForm.action}`,
      message: `Are you sure you want to ${this.dynamicForm.action}?`,
      icon: {
        show: false,
      },
      actions: {
        confirm: {
          show: true,
          label: `${this.dynamicForm.action}`,
          color: 'primary',
        },
        cancel: {
          show: true,
          label: 'Cancel',
        },
      },
      dismissible: false,
    });

    // Subscribe to the confirmation dialog closed action
    confirmation.afterClosed().subscribe((result) => {
      // If the confirm button pressed...
      if (result === 'confirmed') {
        this.onTakeAction();
      }
    });
  }

  async onTakeAction(): Promise<void> {
    const formData = cloneDeep(
      omit(this.universalForm.getRawValue(), this.dynamicForm.excludes)
    ) as any;

    let endpoint = this.dynamicForm.updateOneMethod;
    let action = 'Update';

    if (this.dynamicForm.action === 'Create') {
      action = 'Create';
      endpoint = this.dynamicForm.createOneMethod;
    }

    if (this.dynamicForm.serviceName !== null) {
      this.dynamicForm.serviceName[endpoint](
        formData.id, // Update UniqID
        formData
      ).subscribe({
        next: (response: any) => {
          this.onOpenActionDialog(
            {
              title: `${action} Success`,
              message: '',
              icon: {
                show: false,
              },
              actions: {
                confirm: {
                  show: false,
                  label: 'Close',
                  color: 'primary',
                },
                cancel: {
                  show: true,
                  label: 'Close',
                },
              },
              dismissible: false,
            },
            response
          );
        },
        error: (errorResponse: any) => {
          this.onOpenActionDialog({
            title: `Failed to ${action}`,
            message: errorResponse.error.error.message,
            icon: {
              show: true,
              color: 'warn',
            },
            actions: {
              confirm: {
                show: true,
                label: 'Close',
                color: 'warn',
              },
              cancel: {
                show: false,
                label: 'Cancel',
              },
            },
            dismissible: false,
          });
        },
      });
    } else {
      this.onSendDataToMainComponents(formData);
    }

    this._changeDetectorRef.markForCheck();
  }

  onOpenActionDialog(config: FuseConfirmationConfig, response?: any): void {
    // Open the confirmation dialog
    const confirmation = this._fuseConfirmationService.open(config);

    // Subscribe to the confirmation dialog closed action
    confirmation.afterClosed().subscribe(() => {
      // If the confirm button pressed...
      this.onSendDataToMainComponents(response);
    });

    this._changeDetectorRef.markForCheck();
  }

  onSendDataToMainComponents(modifiedData: any): void {
    const returnData = {
      method: this.dynamicForm.action,
      modifiedData,
    };

    this.formDataEmitter.emit(returnData);
  }

  generateDynamicForm(dynamicForm: iDynamicForm, patchValue: any): void {
    this.addControlsFromFormRows(dynamicForm.mainTableUiRows, patchValue);
    if (dynamicForm.relationArrays.length > 0) {
      for (const relation of dynamicForm.relationArrays) {
        this.addRelationTableControls(
          relation,
          patchValue[relation.relationTable]
        );
      }
    }

    // Patch data if defined
    if (patchValue) {
      this.universalForm.patchValue(patchValue);
    }

    this.universalForm.markAllAsTouched();
  }

  setValueAtPath(obj: any, path: any): string {
    const keys = path.split('.');
    let current = obj;

    for (let i = 0; i < keys.length - 1; i++) {
      const key = keys[i];
      current[key] = current[key] || {};
      current = current[key];
    }

    return current[keys[keys.length - 1]];
  }

  addNewFormGroupToRelationTable(relationTable: string): void {
    const relationFormArray = this.universalForm.get(
      relationTable
    ) as FormArray;

    const relationTableFromArray = this.dynamicForm.relationArrays.find(
      (x) => x.relationTable === relationTable
    );

    const newFormGroup: any = this.formBuilder.group({});
    for (const row of relationTableFromArray.relationUiRows) {
      for (const control of row.blockInputs) {
        const validatorsToAdd: any = this.createValidators(control.validators);

        //
        const controlName =
          control.name + '_' + relationFormArray.controls.length; // Append index to control name
        if (control.type === 'select_infinity') {
          this.blockPages[controlName] = 0; // Use unique control name for blockPages
          this.blockDatas[controlName] = []; // Use unique control name for blockDatas
          this.blockSelected[controlName] = null;

          this.onFetchInfinityScroll(
            control.infinityScrollMetas.dataService,
            controlName,
            control.infinityScrollMetas,
            null
            // controlName
          );
        }

        newFormGroup.addControl(
          control.name,
          this.formBuilder.control(
            {
              // value: control.nestedDataKey,
              value: null,
              disabled:
                (this.dynamicForm.action === 'Create' &&
                  control.disabledOnCreate) ||
                (this.dynamicForm.action === 'Update' &&
                  control.disabledOnUpdate),
            },
            validatorsToAdd
          )
        );
      }
    }

    relationFormArray.push(newFormGroup);
  }

  deleteRelationTable(uiRow: number, relationTable: any): void {
    const relationFormArray = this.universalForm.get(
      relationTable
    ) as FormArray;

    if (uiRow !== -1) {
      relationFormArray.controls.splice(uiRow, 1);
    }
  }

  isUnset(data: any): boolean {
    if (data === '' || typeof data === 'undefined' || data == null) {
      return true;
    }

    return false;
  }

  onSelectedSearchValue(
    allItemLists: any[],
    formGroup: FormGroup,
    formControlKey: string,
    controlIndex: number,
    findDataKey: string,
    selectItemCode: string
  ): void {
    const findItem = allItemLists.find(
      (obj) => obj[findDataKey] === selectItemCode
    );

    if (findItem) {
      formGroup.get(formControlKey).patchValue(findItem[findDataKey]);
    }

    // Mark for check
    this._changeDetectorRef.markForCheck();
  }

  getSelectedHuman(
    allItemLists: any[],
    findDataKey: string,
    curVal: string,
    returnDataKey: string
  ): string {
    if (curVal === '') {
      return 'Select Data';
    }

    const getData = allItemLists.find((x) => x[findDataKey] === curVal);
    if (!getData) {
      return 'Select Data';
    }

    // Mark for check
    this._changeDetectorRef.markForCheck();
    return getData[returnDataKey];
  }

  onToggleTriggerItem(
    formGroup: FormGroup,
    formControlKey: string,
    itemUniq: string | number
  ): void {
    // Guard
    if (!itemUniq) {
      console.warn('Undefined Input Detected');
      return;
    }
    // Get the current value of the form control
    let currentValue = formGroup.get(formControlKey).value;

    if (currentValue === null) {
      currentValue = [];
    }

    // Patch the new array value into the form control
    const newValue = currentValue.includes(itemUniq)
      ? currentValue.filter((id) => id !== itemUniq) // Remove quest.id if it exists
      : [...currentValue, itemUniq]; // Add quest.id if it doesn't exist

    // Patch the new array value into the form control
    formGroup.get(formControlKey).patchValue(newValue);
    // Mark for check
    this._changeDetectorRef.markForCheck();
  }

  getToggleTriggerItemDisplay(
    arrayData: any[],
    seekKey: string,
    dataKey: string,
    data: string
  ): string {
    const getData = arrayData.find((x) => x[seekKey] === data);
    return getData[dataKey];
  }

  updateTime(dateControlName: string): void {
    const hourControlName = dateControlName + '_hour';
    const minuteControlName = dateControlName + '_minute';

    const hour = this.universalForm.get(hourControlName).value;
    const minute = this.universalForm.get(minuteControlName).value;
    const date = this.universalForm.get(dateControlName).value; // Get current date value

    // Combine date with updated time
    const updatedDateTime = new Date(date);
    updatedDateTime.setHours(hour);
    updatedDateTime.setMinutes(minute);

    // Update main date control with combined date and time
    this.universalForm
      .get(dateControlName)
      .setValue(updatedDateTime.toISOString());
  }

  onSelectedInfinityScrollItem(
    value: string,
    formGroup: FormGroup,
    blockName: string,
    blockMeta: infinityScrollMetas,
    blockIdentity: string
  ): void {
    // Use the value and blockName as needed
    const find = this.blockDatas[blockIdentity].find((obj) => obj.id === value);

    let isIncompletedConfig = false;
    if (
      !blockMeta.optionDisplayKey ||
      blockMeta.optionDisplayKey === '' ||
      !blockMeta.optionDisplayKey2 ||
      blockMeta.optionDisplayKey2 === ''
    ) {
      isIncompletedConfig = true;
    }

    this.blockSelected[blockIdentity] = {
      id: value,
      displayVal1: isIncompletedConfig
        ? 'Check Configulation'
        : find[blockMeta.optionDisplayKey],
      displayVal2: isIncompletedConfig
        ? 'Check Configulation'
        : find[blockMeta.optionDisplayKey2],
    };

    formGroup.get(blockName).patchValue(value);

    // Mark for check
    this._changeDetectorRef.markForCheck();
  }

  isValidMongoId(value: string): boolean {
    // Regular expression to validate MongoDB ObjectId
    const mongoIdPattern = /^[0-9a-fA-F]{24}$/;
    return mongoIdPattern.test(value);
  }

  onFetchInfinityScroll(
    service: any,
    blockName: string,
    blockMeta: infinityScrollMetas,
    initValue: string = null
  ): void {
    service
      .getDataWithPagination(
        this.blockPages[blockName], // Current Page
        20,
        'createdAt',
        'desc',
        this.blockSearchInputs[blockName].value ?? ''
      )
      .subscribe((res: any) => {
        if (res.data.length === 0) {
          this.blockDatas[blockName] = [];
        } else {
          this.blockDatas[blockName] = [
            ...this.blockDatas[blockName],
            ...res.data,
          ];
        }

        if (initValue) {
          const findInitValInPagination = this.blockDatas[blockName].find(
            (obj) => obj.id === initValue
          );

          if (!findInitValInPagination) {
            if (this.isValidMongoId(initValue)) {
              service.getById(initValue).subscribe((obj: any) => {
                if (obj) {
                  this.blockDatas[blockName] = [
                    obj,
                    ...this.blockDatas[blockName],
                  ];

                  this.blockSelected[blockName] = {
                    id: obj[blockMeta.optionValueKey],
                    displayVal1: obj[blockMeta.optionDisplayKey],
                    displayVal2: obj[blockMeta.optionDisplayKey2],
                  };
                }
              });
            }
          } else {
            const object = this.blockDatas[blockName].find(
              (obj) => obj.id === initValue
            );

            const findIndexRemove = this.blockDatas[blockName].indexOf(object);
            if (findIndexRemove > -1) {
              this.blockDatas[blockName].splice(findIndexRemove, 1);
            }

            this.blockDatas[blockName].unshift(object);

            this.blockSelected[blockName] = {
              id: object[blockMeta.optionValueKey],
              displayVal1: object[blockMeta.optionDisplayKey],
              displayVal2: object[blockMeta.optionDisplayKey2],
            };
          }
        }
      });

    // Increment pages count for the specific block
    this.blockPages[blockName]++;
  }

  onSearchInfinityScroll(service: any, blockName: string): void {
    this.blockPages[blockName] = 0;
    this.blockDatas[blockName] = [];
    service
      .getDataWithPagination(
        this.blockPages[blockName], // Current Page
        20,
        'createdAt',
        'desc',
        this.blockSearchInputs[blockName].value ?? ''
      )
      .subscribe((res: any) => {
        if (res.data.length === 0) {
          this.blockDatas[blockName] = [];
        } else {
          this.blockDatas[blockName] = [
            ...this.blockDatas[blockName],
            ...res.data,
          ];
        }
      });
  }

  onCloseResetSearchInput(
    service: any,
    blockName: string,
    blockMeta: infinityScrollMetas,
  ): void {
    this.blockSearchInputs[blockName].setValue('');
    if (this.blockDatas[blockName].length === 0) {
      this.onFetchInfinityScroll(service, blockName, blockMeta, null);
    }


  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  private addControlsFromFormRows(formRows: any[], patchValues: any): void {
    for (const row of formRows) {
      if (typeof row.blockInputs !== 'undefined') {
        if (this.dynamicForm.action === DYNAMIC_FORM_ACTION.CREATE) {
          row.blockInputs = row.blockInputs.filter(
            (x: iDynamicFormEach) => x.name !== 'id'
          );
        }

        if (this.dynamicForm.action === DYNAMIC_FORM_ACTION.UPDATE) {
          row.blockInputs = row.blockInputs.filter(
            (x: iDynamicFormEach) => !x.hiddenOnUpdate
          );
        }

        for (const control of row.blockInputs) {
          const validatorsToAdd: any = this.createValidators(
            control.validators
          );
          this.addControlToUniversalForm(control, validatorsToAdd, patchValues);
        }
      }
    }
  }

  private addControlToUniversalForm(
    control: iDynamicFormEach,
    validatorsToAdd: any[],
    patchVal: any
  ): void {
    if (control.name !== undefined) {
      let initialValue = null;

      if (patchVal) {
        if (control.nestedDataKey) {
          initialValue = this.setValueAtPath(patchVal, control.nestedDataKey);
        } else {
          initialValue = this.setValueAtPath(patchVal, control.name);
        }
      } else {
        if (control.type === DYNAMIC_FORM_INPUT.trigger_list) {
          initialValue = [];
        }
      }

      if (control.type === 'select_infinity') {
        this.blockPages[control.name] = 0; // Start at 0 instead of 1
        this.blockDatas[control.name] = []; // Initialize an empty array for data
        this.blockSelected[control.name] = null;
        this.blockSearchInputs[control.name] = new UntypedFormControl('');

        this.onFetchInfinityScroll(
          control.infinityScrollMetas.dataService,
          control.name,
          control.infinityScrollMetas,
          initialValue
          // control.name
        );
      }

      this.universalForm.addControl(
        control.name,
        this.formBuilder.control(
          {
            value: initialValue,
            disabled:
              (this.dynamicForm.action === 'Create' &&
                control.disabledOnCreate) ||
              (this.dynamicForm.action === 'Update' &&
                control.disabledOnUpdate),
          },
          validatorsToAdd
        )
      );

      // Add time picker control dynamically
      if (control.type === 'datepicker') {
        // const currentDate = new Date();
        // const currentHour = currentDate.getHours();
        // const currentMinute = currentDate.getMinutes();

        const oldDate = new Date(this.universalForm.get(control.name).value);
        const oldHour = oldDate.getHours();
        const oldMinute = oldDate.getMinutes();

        this.universalForm.addControl(
          control.name + '_hour',
          this.formBuilder.control(oldHour, [
            Validators.min(0),
            Validators.max(23),
          ])
        );

        this.universalForm.addControl(
          control.name + '_minute',
          this.formBuilder.control(oldMinute, [
            Validators.min(0),
            Validators.max(59),
          ])
        );
      }
    }
  }

  private addRelationTableControls(relation: any, patchValues: any[]): void {
    if (!this.universalForm.get(relation.relationTable)) {
      this.universalForm.addControl(
        relation.relationTable,
        this.formBuilder.array([])
      );
    }

    const relationTableArray = this.universalForm.get(
      relation.relationTable
    ) as FormArray;

    if (patchValues) {
      let index = 0; // Initialize index
      for (const patchVal of patchValues) {
        const formGroup: any = {};
        for (const row of relation.relationUiRows) {
          for (const control of row.blockInputs) {
            const controlName = control.name + '_' + index; // Append index to control name

            const validatorsToAdd: any = this.createValidators(
              control.validators
            );

            if (control.type === 'select_infinity') {
              this.blockPages[controlName] = 0; // Use unique control name for blockPages
              this.blockDatas[controlName] = []; // Use unique control name for blockDatas
              this.blockSelected[controlName] = null;

              this.onFetchInfinityScroll(
                control.infinityScrollMetas.dataService,
                controlName,
                control.infinityScrollMetas,
                patchVal[control.name]
                // controlName
              );
            }

            this.addControlToFormGroup(
              formGroup,
              control,
              validatorsToAdd,
              patchVal
            );
          }
        }

        relationTableArray.push(this.formBuilder.group(formGroup));
        index++; // Increment index
      }
    }
  }

  private addControlToFormGroup(
    formGroup: any,
    control: iDynamicFormEach,
    validatorsToAdd: any[],
    patchVal: any
  ): void {
    if (control.name !== undefined) {
      let initialValue = null;
      if (control.nestedDataKey === '') {
        initialValue = patchVal[control.name];
      } else {
        initialValue = this.setValueAtPath(patchVal, control.nestedDataKey);
      }

      formGroup[control.name] = this.formBuilder.control(
        {
          value: initialValue,
          disabled:
            this.dynamicForm.action === 'Update' && control.disabledOnUpdate,
        },
        validatorsToAdd
      );
    }
  }

  // Form Validators
  private createValidators(validatorConfig: any): any[] {
    const validatorsToAdd: any[] = [];
    for (const [key, value] of Object.entries(validatorConfig)) {
      switch (key) {
        case 'min':
        case 'max':
          validatorsToAdd.push(Validators[key](value as number));
          break;
        case 'required':
          if (value) {
            validatorsToAdd.push(Validators.required);
          }
          break;
        case 'email':
          if (value) {
            validatorsToAdd.push(Validators.email);
          }
          break;
        case 'minLength':
          validatorsToAdd.push(Validators.minLength(value as number));
          break;
        case 'maxLength':
          validatorsToAdd.push(Validators.maxLength(value as number));
          break;
        case 'pattern':
          validatorsToAdd.push(Validators.pattern(value as string));
          break;
        case 'nullValidator':
          if (value) {
            validatorsToAdd.push(Validators.nullValidator);
          }
          break;
        case 'trimValidator':
          if (value) {
            validatorsToAdd.push(TrimValidator);
          }
          break;
        case 'customRequired':
          if (value) {
            validatorsToAdd.push(CustomRequired);
          }
          break;
        case 'numberOnlyValidator':
          if (value) {
            validatorsToAdd.push(NumberOnlyValidator);
          }
          break;
        default:
          break;
      }
    }

    return validatorsToAdd;
  }
}
