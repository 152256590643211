import { BaseModel } from 'app/utils/core.types';

export enum UserType {
    PLAYER = 'PLAYER',
    GAME_ADMIN = 'GAME_ADMIN',
    BACKEND_ADMIN = 'BACKEND_ADMIN',
    ROOT_ADMIN = 'ROOT_ADMIN',
}

export enum UserTypePriority {
    PLAYER = 0,
    GAME_ADMIN = 10,
    BACKEND_ADMIN = 100,
    ROOT_ADMIN = 999,
}

export enum UserClassColor {
    PLAYER = 'label-uncommon',
    GAME_ADMIN = 'label-rare',
    BACKEND_ADMIN = 'label-epic',
    ROOT_ADMIN = 'label-legendary',
}

export interface UserPlayer extends BaseModel {
    email: string;
    name: string;
    image_url?: string;
    is_suspend: boolean;
    userType: UserType;
    password: string;
}


export interface CreateUserPlayer extends BaseModel {
    email: string;
    name: string;
    image_url?: string;
    is_suspend: boolean;
    userType: UserType;
    password: string;
}
