import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputAutoComplateOffModule } from 'app/utils/directives/auto-complate-off/input-auto-complate-off.module';
import { MatInputModule } from '@angular/material/input';
import { MatFormGenerateComponent } from './components/mat-form-generate/mat-form-generate.component';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatSelectModule } from '@angular/material/select';
import { SearchByKeyModule } from 'app/utils/pipes/search-by-key/search-by-key.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    InputAutoComplateOffModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatIconModule,
    MatInputModule,
    MatMomentDateModule,
    MatRippleModule,
    MatSelectInfiniteScrollModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSortModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatMenuModule,
    MatPaginatorModule,
    MatSidenavModule,
    MatTableModule,
    MatTabsModule,
    ReactiveFormsModule,
    ScrollingModule,
    SearchByKeyModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputAutoComplateOffModule,
    MatFormGenerateComponent,
  ],
  declarations: [MatFormGenerateComponent],
})
export class SharedModule {}
