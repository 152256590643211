import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { RoleGuard } from './core/auth/guards/roles.guard';
import { ROLES } from './utils/constanst/constanst';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
  // Redirect empty path to '/apps/newsletter'
  { path: '', pathMatch: 'full', redirectTo: 'apps/newsletter' },

  // Redirect signed in user to the '/apps/newsletter'
  //
  // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  {
    path: 'signed-in-redirect',
    pathMatch: 'full',
    redirectTo: 'apps/newsletter',
  },

  // Auth routes for guests
  {
    path: '',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'confirmation-required',
        loadChildren: () =>
          import(
            'app/modules/auth/confirmation-required/confirmation-required.module'
          ).then((m) => m.AuthConfirmationRequiredModule),
      },
      {
        path: 'forgot-password',
        loadChildren: () =>
          import(
            'app/modules/auth/forgot-password/forgot-password.module'
          ).then((m) => m.AuthForgotPasswordModule),
      },
      {
        path: 'reset-password',
        loadChildren: () =>
          import('app/modules/auth/reset-password/reset-password.module').then(
            (m) => m.AuthResetPasswordModule
          ),
      },
      {
        path: 'sign-in',
        loadChildren: () =>
          import('app/modules/auth/sign-in/sign-in.module').then(
            (m) => m.AuthSignInModule
          ),
      },
      {
        path: 'sign-up',
        loadChildren: () =>
          import('app/modules/auth/sign-up/sign-up.module').then(
            (m) => m.AuthSignUpModule
          ),
      },
    ],
  },

  // Auth routes for authenticated users
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'sign-out',
        loadChildren: () =>
          import('app/modules/auth/sign-out/sign-out.module').then(
            (m) => m.AuthSignOutModule
          ),
      },
      {
        path: 'unlock-session',
        loadChildren: () =>
          import('app/modules/auth/unlock-session/unlock-session.module').then(
            (m) => m.AuthUnlockSessionModule
          ),
      },
    ],
  },

  // Landing routes
  // {
  //     path: '',
  //     component: LayoutComponent,
  //     data: {
  //         layout: 'empty'
  //     },
  //     children: [
  //         { path: 'home', loadChildren: () => import('app/modules/landing/home/home.module').then(m => m.LandingHomeModule) },
  //     ]
  // },

  // Admin routes
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      // Dashboards
      // {
      //     path: 'dashboards', children: [
      //         { path: 'project', loadChildren: () => import('app/modules/admin/dashboards/project/project.module').then(m => m.ProjectModule) },
      //         { path: 'analytics', loadChildren: () => import('app/modules/admin/dashboards/analytics/analytics.module').then(m => m.AnalyticsModule) },
      //         { path: 'finance', loadChildren: () => import('app/modules/admin/dashboards/finance/finance.module').then(m => m.FinanceModule) },
      //         { path: 'crypto', loadChildren: () => import('app/modules/admin/dashboards/crypto/crypto.module').then(m => m.CryptoModule) },
      //     ]
      // },

      // Apps
      {
        path: 'apps',
        children: [
          {
            path: 'newsletter',
            canActivate: [RoleGuard],
            data: { expectedRoles: ROLES.All_BACKEND_USERS },
            loadChildren: () =>
              import(
                'app/modules/admin/apps/newsletter/newsletter.module'
              ).then((m) => m.NewsletterModule),
          },
          {
            path: 'quests',
            canActivate: [RoleGuard],
            data: { expectedRoles: ROLES.All_BACKEND_USERS },
            loadChildren: () =>
              import('app/modules/admin/apps/quest/quest.module').then(
                (m) => m.QuestModule
              ),
          },
          {
            path: 'player',
            canActivate: [RoleGuard],
            data: { expectedRoles: ROLES.All_BACKEND_USERS },
            loadChildren: () =>
              import('app/modules/admin/apps/player/player.module').then(
                (m) => m.PlayerModule
              ),
          },
          {
            path: 'chat-room',
            canActivate: [RoleGuard],
            data: { expectedRoles: ROLES.All_BACKEND_USERS },
            loadChildren: () =>
              import('app/modules/admin/apps/chat-room/chat-room.module').then(
                (m) => m.ChatRoomModule
              ),
          },
          {
            path: 'gashapon',
            canActivate: [RoleGuard],
            data: { expectedRoles: ROLES.All_BACKEND_USERS },
            loadChildren: () =>
              import('app/modules/admin/apps/gashapon/gashapon.module').then(
                (m) => m.GashaponModule
              ),
          },
          {
            path: 'ms-enemy',
            canActivate: [RoleGuard],
            data: { expectedRoles: ROLES.ROOT_ADMIN },
            loadChildren: () =>
              import('app/modules/admin/apps/ms-enemy/ms-enemy.module').then(
                (m) => m.MsEnemyModule
              ),
          },
          {
            path: 'enemy',
            canActivate: [RoleGuard],
            data: { expectedRoles: ROLES.ROOT_ADMIN },
            loadChildren: () =>
              import('app/modules/admin/apps/enemy/enemy.module').then(
                (m) => m.EnemyModule
              ),
          },
          {
            path: 'map-area',
            canActivate: [RoleGuard],
            data: { expectedRoles: ROLES.ROOT_ADMIN },
            loadChildren: () =>
              import('app/modules/admin/apps/map-area/map-area.module').then(
                (m) => m.MapAreaModule
              ),
          },
          {
            path: 'map-location',
            canActivate: [RoleGuard],
            data: { expectedRoles: ROLES.ROOT_ADMIN },
            loadChildren: () =>
              import(
                'app/modules/admin/apps/map-location/map-location.module'
              ).then((m) => m.MapLocationModule),
          },
          {
            path: 'enemy-wave-group',
            canActivate: [RoleGuard],
            data: { expectedRoles: ROLES.ROOT_ADMIN },
            loadChildren: () =>
              import(
                'app/modules/admin/apps/enemy-wave-group/enemy-wave-group.module'
              ).then((m) => m.EnemyWaveGroupModule),
          },
          {
            path: 'ms-item-type',
            canActivate: [RoleGuard],
            data: { expectedRoles: ROLES.ROOT_ADMIN },
            loadChildren: () =>
              import(
                'app/modules/admin/apps/ms-item-type/ms-item-type.module'
              ).then((m) => m.MsItemTypeModule),
          },
          {
            path: 'ms-pet-part',
            canActivate: [RoleGuard],
            data: { expectedRoles: ROLES.ROOT_ADMIN },
            loadChildren: () =>
              import(
                'app/modules/admin/apps/ms-pet-part/ms-pet-part.module'
              ).then((m) => m.MsPetPartModule),
          },
          {
            path: 'ms-pet-part-random-table',
            canActivate: [RoleGuard],
            data: { expectedRoles: ROLES.ROOT_ADMIN },
            loadChildren: () =>
              import(
                'app/modules/admin/apps/ms-pet-part-random-table/ms-pet-part-random-table.module'
              ).then((m) => m.MsPetPartRandomTableModule),
          },
          {
            path: 'ms-level-pet-stat',
            canActivate: [RoleGuard],
            data: { expectedRoles: ROLES.ROOT_ADMIN },
            loadChildren: () =>
              import(
                'app/modules/admin/apps/ms-level-pet-stat/ms-level-pet-stat.module'
              ).then((m) => m.MsLevelPetStatModule),
          },
          {
            path: 'ms-pet-skill',
            canActivate: [RoleGuard],
            data: { expectedRoles: ROLES.ROOT_ADMIN },
            loadChildren: () =>
              import(
                'app/modules/admin/apps/ms-pet-skill/ms-pet-skill.module'
              ).then((m) => m.MsPetSkillModule),
          },
          {
            path: 'pet-name-pool',
            canActivate: [RoleGuard],
            data: { expectedRoles: ROLES.ROOT_ADMIN },
            loadChildren: () =>
              import(
                'app/modules/admin/apps/pet-name-pool/pet-name-pool.module'
              ).then((m) => m.PetNamePoolModule),
          },
          {
            path: 'ms-avatar-part-option',
            canActivate: [RoleGuard],
            data: { expectedRoles: ROLES.ROOT_ADMIN },
            loadChildren: () =>
              import(
                'app/modules/admin/apps/ms-avatar-part-option/ms-avatar-part-option.module'
              ).then((m) => m.MsAvatarPartOptionModule),
          },
          {
            path: 'authorization',
            canActivate: [RoleGuard],
            data: { expectedRoles: ROLES.ROOT_ADMIN },
            loadChildren: () =>
              import(
                'app/modules/admin/apps/user-player/user-player.module'
              ).then((m) => m.UserPlayerModule),
          },
          {
            path: 'ms-pet-species',
            canActivate: [RoleGuard],
            data: { expectedRoles: ROLES.ROOT_ADMIN },
            loadChildren: () =>
              import(
                'app/modules/admin/apps/ms-pet-species/ms-pet-species.module'
              ).then((m) => m.MsPetSpeciesModule),
          },
          {
            path: 'ms-guild-icon-cat',
            canActivate: [RoleGuard],
            data: { expectedRoles: ROLES.ROOT_ADMIN },
            loadChildren: () =>
              import(
                'app/modules/admin/apps/ms-guild-icon-cat/ms-guild-icon-cat.module'
              ).then((m) => m.MsGuildIconCatModule),
          },
          {
            path: 'ms-guild-icon',
            canActivate: [RoleGuard],
            data: { expectedRoles: ROLES.ROOT_ADMIN },
            loadChildren: () =>
              import(
                'app/modules/admin/apps/ms-guild-icon/ms-guild-icon.module'
              ).then((m) => m.MsGuildIconModule),
          },
          {
            path: 'ms-guild-dungeon',
            canActivate: [RoleGuard],
            data: { expectedRoles: ROLES.ROOT_ADMIN },
            loadChildren: () =>
              import(
                'app/modules/admin/apps/ms-guild-dungeon/ms-guild-dungeon.module'
              ).then((m) => m.MsGuildDungeonModule),
          },
          {
            path: 'ms-pet-evo-step-stats',
            canActivate: [RoleGuard],
            data: { expectedRoles: ROLES.ROOT_ADMIN },
            loadChildren: () =>
              import(
                'app/modules/admin/apps/ms-pet-evo-step-stats/ms-pet-evo-step-stats.module'
              ).then((m) => m.MsPetEvoStepStatsModule),
          },
          {
            path: 'ms-pet-part-skill-path',
            canActivate: [RoleGuard],
            data: { expectedRoles: ROLES.ROOT_ADMIN },
            loadChildren: () =>
              import(
                'app/modules/admin/apps/ms-pet-part-skill-path/ms-pet-part-skill-path.module'
              ).then((m) => m.MsPetPartSkillPathModule),
          },
          {
            path: 'ms-pet-part-passive-skill-path',
            canActivate: [RoleGuard],
            data: { expectedRoles: ROLES.ROOT_ADMIN },
            loadChildren: () =>
              import(
                'app/modules/admin/apps/ms-pet-part-passive-skill-path/ms-pet-part-passive-skill-path.module'
              ).then((m) => m.MsPetPartPassiveSkillPathModule),
          },
          {
            path: 'map-area-config',
            canActivate: [RoleGuard],
            data: { expectedRoles: ROLES.ROOT_ADMIN },
            loadChildren: () =>
              import(
                'app/modules/admin/apps/map-area-config/map-area-config.module'
              ).then((m) => m.MapAreaConfigModule),
          },
          {
            path: 'miniboss-config',
            canActivate: [RoleGuard],
            data: { expectedRoles: ROLES.ROOT_ADMIN },
            loadChildren: () =>
              import(
                'app/modules/admin/apps/mini-boss-config/mini-boss-config.module'
              ).then((m) => m.MiniBossConfigModule),
          },
          {
            path: 'msworld-boss-config',
            canActivate: [RoleGuard],
            data: { expectedRoles: ROLES.ROOT_ADMIN },
            loadChildren: () =>
              import(
                'app/modules/admin/apps/ms-world-boss-config/ms-world-boss-config.module'
              ).then((m) => m.MsWorldBossConfigModule),
          },
          {
            path: 'game-statistics',
            canActivate: [RoleGuard],
            data: { expectedRoles: ROLES.ROOT_ADMIN },
            loadChildren: () =>
              import(
                'app/modules/admin/apps/game-statistics/game-statistics.module'
              ).then((m) => m.GameStatisticsModule),
          },
          {
            path: 'distribute-item',
            canActivate: [RoleGuard],
            data: { expectedRoles: ROLES.ROOT_ADMIN },
            loadChildren: () =>
              import(
                'app/modules/admin/apps/distribute-item/distribute-item.module'
              ).then((m) => m.DistributeItemModule),
          },

          // { path: 'academy', loadChildren: () => import('app/modules/admin/apps/academy/academy.module').then(m => m.AcademyModule) },
          // { path: 'chat', loadChildren: () => import('app/modules/admin/apps/chat/chat.module').then(m => m.ChatModule) },
          // { path: 'contacts', loadChildren: () => import('app/modules/admin/apps/contacts/contacts.module').then(m => m.ContactsModule) },
          // { path: 'ecommerce', loadChildren: () => import('app/modules/admin/apps/ecommerce/ecommerce.module').then(m => m.ECommerceModule) },
          // { path: 'file-manager', loadChildren: () => import('app/modules/admin/apps/file-manager/file-manager.module').then(m => m.FileManagerModule) },
          // { path: 'help-center', loadChildren: () => import('app/modules/admin/apps/help-center/help-center.module').then(m => m.HelpCenterModule) },
          // { path: 'mailbox', loadChildren: () => import('app/modules/admin/apps/mailbox/mailbox.module').then(m => m.MailboxModule) },
          // { path: 'notes', loadChildren: () => import('app/modules/admin/apps/notes/notes.module').then(m => m.NotesModule) },
          // { path: 'scrumboard', loadChildren: () => import('app/modules/admin/apps/scrumboard/scrumboard.module').then(m => m.ScrumboardModule) },
          // { path: 'tasks', loadChildren: () => import('app/modules/admin/apps/tasks/tasks.module').then(m => m.TasksModule) },
        ],
      },

      // Pages
      // {
      //     path: 'pages', children: [

      //         // Activities
      //         { path: 'activities', loadChildren: () => import('app/modules/admin/pages/activities/activities.module').then(m => m.ActivitiesModule) },

      //         // Authentication
      //         { path: 'authentication', loadChildren: () => import('app/modules/admin/pages/authentication/authentication.module').then(m => m.AuthenticationModule) },

      //         // Coming Soon
      //         { path: 'coming-soon', loadChildren: () => import('app/modules/admin/pages/coming-soon/coming-soon.module').then(m => m.ComingSoonModule) },

      //         // Error
      //         {
      //             path: 'error', children: [
      //                 { path: '404', loadChildren: () => import('app/modules/admin/pages/error/error-404/error-404.module').then(m => m.Error404Module) },
      //                 { path: '500', loadChildren: () => import('app/modules/admin/pages/error/error-500/error-500.module').then(m => m.Error500Module) }
      //             ]
      //         },

      //         // Invoice
      //         {
      //             path: 'invoice', children: [
      //                 {
      //                     path: 'printable', children: [
      //                         { path: 'compact', loadChildren: () => import('app/modules/admin/pages/invoice/printable/compact/compact.module').then(m => m.CompactModule) },
      //                         { path: 'modern', loadChildren: () => import('app/modules/admin/pages/invoice/printable/modern/modern.module').then(m => m.ModernModule) }
      //                     ]
      //                 }
      //             ]
      //         },

      //         // Maintenance
      //         { path: 'maintenance', loadChildren: () => import('app/modules/admin/pages/maintenance/maintenance.module').then(m => m.MaintenanceModule) },

      //         // Pricing
      //         {
      //             path: 'pricing', children: [
      //                 { path: 'modern', loadChildren: () => import('app/modules/admin/pages/pricing/modern/modern.module').then(m => m.PricingModernModule) },
      //                 { path: 'simple', loadChildren: () => import('app/modules/admin/pages/pricing/simple/simple.module').then(m => m.PricingSimpleModule) },
      //                 { path: 'single', loadChildren: () => import('app/modules/admin/pages/pricing/single/single.module').then(m => m.PricingSingleModule) },
      //                 { path: 'table', loadChildren: () => import('app/modules/admin/pages/pricing/table/table.module').then(m => m.PricingTableModule) }
      //             ]
      //         },

      //         // Profile
      //         { path: 'profile', loadChildren: () => import('app/modules/admin/pages/profile/profile.module').then(m => m.ProfileModule) },

      //         // Settings
      //         { path: 'settings', loadChildren: () => import('app/modules/admin/pages/settings/settings.module').then(m => m.SettingsModule) },
      //     ]
      // },

      // User Interface
      // {
      //     path: 'ui', children: [

      //         // Material Components
      //         { path: 'material-components', loadChildren: () => import('app/modules/admin/ui/material-components/material-components.module').then(m => m.MaterialComponentsModule) },

      //         // Fuse Components
      //         { path: 'fuse-components', loadChildren: () => import('app/modules/admin/ui/fuse-components/fuse-components.module').then(m => m.FuseComponentsModule) },

      //         // Other Components
      //         { path: 'other-components', loadChildren: () => import('app/modules/admin/ui/other-components/other-components.module').then(m => m.OtherComponentsModule) },

      //         // TailwindCSS
      //         { path: 'tailwindcss', loadChildren: () => import('app/modules/admin/ui/tailwindcss/tailwindcss.module').then(m => m.TailwindCSSModule) },

      //         // Advanced Search
      //         { path: 'advanced-search', loadChildren: () => import('app/modules/admin/ui/advanced-search/advanced-search.module').then(m => m.AdvancedSearchModule) },

      //         // Animations
      //         { path: 'animations', loadChildren: () => import('app/modules/admin/ui/animations/animations.module').then(m => m.AnimationsModule) },

      //         // Cards
      //         { path: 'cards', loadChildren: () => import('app/modules/admin/ui/cards/cards.module').then(m => m.CardsModule) },

      //         // Colors
      //         { path: 'colors', loadChildren: () => import('app/modules/admin/ui/colors/colors.module').then(m => m.ColorsModule) },

      //         // Confirmation Dialog
      //         { path: 'confirmation-dialog', loadChildren: () => import('app/modules/admin/ui/confirmation-dialog/confirmation-dialog.module').then(m => m.ConfirmationDialogModule) },

      //         // Datatable
      //         { path: 'datatable', loadChildren: () => import('app/modules/admin/ui/datatable/datatable.module').then(m => m.DatatableModule) },

      //         // Forms
      //         {
      //             path: 'forms', children: [
      //                 { path: 'fields', loadChildren: () => import('app/modules/admin/ui/forms/fields/fields.module').then(m => m.FormsFieldsModule) },
      //                 { path: 'layouts', loadChildren: () => import('app/modules/admin/ui/forms/layouts/layouts.module').then(m => m.FormsLayoutsModule) },
      //                 { path: 'wizards', loadChildren: () => import('app/modules/admin/ui/forms/wizards/wizards.module').then(m => m.FormsWizardsModule) }
      //             ]
      //         },

      //         // Icons
      //         { path: 'icons', loadChildren: () => import('app/modules/admin/ui/icons/icons.module').then(m => m.IconsModule) },

      //         // Page Layouts
      //         { path: 'page-layouts', loadChildren: () => import('app/modules/admin/ui/page-layouts/page-layouts.module').then(m => m.PageLayoutsModule) },

      //         // Typography
      //         { path: 'typography', loadChildren: () => import('app/modules/admin/ui/typography/typography.module').then(m => m.TypographyModule) }
      //     ]
      // },

      // Documentation
      // {
      //     path: 'docs', children: [

      //         // Changelog
      //         { path: 'changelog', loadChildren: () => import('app/modules/admin/docs/changelog/changelog.module').then(m => m.ChangelogModule) },

      //         // Guides
      //         { path: 'guides', loadChildren: () => import('app/modules/admin/docs/guides/guides.module').then(m => m.GuidesModule) }
      //     ]
      // },

      // 404 & Catch all
      {
        path: '404-not-found',
        pathMatch: 'full',
        loadChildren: () =>
          import(
            'app/modules/admin/pages/error/error-404/error-404.module'
          ).then((m) => m.Error404Module),
      },
      { path: '**', redirectTo: '404-not-found' },
    ],
  },
];
