import { Directive, ElementRef } from '@angular/core';

@Directive({
    selector: 'input[matInput]:not([type="number"]),textarea[matInput]',
})
export class InputAutoCompleteOffDirective {
    constructor(private _el: ElementRef) {
        this._el.nativeElement.setAttribute('autocomplete', 'off');
        this._el.nativeElement.setAttribute('autocorrect', 'off');
        this._el.nativeElement.setAttribute('autocapitalize', 'off');
        this._el.nativeElement.setAttribute('spellcheck', 'false');
    }
}
