import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'searchByKey',
})
export class SearchByKeyPipe implements PipeTransform {
    transform(
        source: any[],
        key: string | string[],
        searchText: string = ''
    ): unknown {
        if (!source || source.length === 0) {
            return [];
        }

        if (!searchText || searchText.length === 0) {
            return source;
        }

        const value = searchText.toLowerCase();

        if (!Array.isArray(key)) {
            return source.filter((obj) =>
                obj[key].toLowerCase().includes(value)
            );
        }

        return source.filter((obj) => {
            let isMatch = false;
            for (const k of key) {
                const check = obj[k]?.toLowerCase().includes(value);
                if (check) {
                    isMatch = true;
                    break;
                }
            }

            return isMatch;
        });
    }
}
