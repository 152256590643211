<form [formGroup]="universalForm" class="text-sm">
  <!-- Main Table UI -->
  <div *ngFor="let mainRow of dynamicForm?.mainTableUiRows">
    <div class="px-4">
      <div class="font-semibold text-primary">{{ mainRow.title }}</div>
    </div>
    <div class="flex flex-col sm:flex-row p-2">
      <div class="flex flex-auto flex-wrap">
        <div
          *ngFor="let block of mainRow.blockInputs"
          class="flex flex-col w-full sm:px-4"
          [ngClass]="[block.size]"
        >
          <ng-container *ngIf="dynamicForm.readonly; else haveAccessOnForm">
            <div class="div w-full mb-4">
              <div class="div-wrapper">
                <div class="div-flex">
                  <div class="div-infix">
                    <mat-label class="font-medium text-sm">{{
                      block.label
                    }}</mat-label>
                  </div>
                </div>
                <div class="text-md text-secondary">
                  <ng-container
                    *ngIf="
                      !isUnset(universalForm.get(block.name).value);
                      else noSettleData
                    "
                  >
                    <span class>{{ universalForm.get(block.name).value }}</span>
                  </ng-container>
                  <ng-template #noSettleData>
                    <span
                      class="inline-flex items-center font-medium text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50"
                    >
                      <span class="leading-relaxed whitespace-nowrap"
                        >Field have no data</span
                      >
                    </span>
                  </ng-template>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-template #haveAccessOnForm>
            <ng-container
              *ngIf="['text', 'number', 'password'].includes(block.type)"
            >
              <mat-form-field class="w-full">
                <mat-label class="font-medium text-sm">{{
                  block.label
                }}</mat-label>
                <!-- Mapping Input Type -->
                <input
                  class="text-sm"
                  matInput
                  [formControlName]="block.name"
                  [type]="block.type"
                  [readonly]="
                    block.readonly ||
                    block.disableOnUpdate ||
                    block.disabledOnCreate
                  "
                />
                <!-- Error Message -->
                <mat-error
                  *ngIf="universalForm.get(block.name).hasError('required')"
                >
                  Required
                </mat-error>
                <mat-error
                  *ngIf="universalForm.get(block.name).hasError('trimError')"
                >
                  {{ universalForm.get(block.name).errors.trimError }}
                </mat-error>
                <mat-error *ngIf="universalForm.get(block.name).hasError('min')"
                  >Must be at least
                  {{ universalForm.get(block.name).errors?.min.min }}
                </mat-error>
                <mat-error *ngIf="universalForm.get(block.name).hasError('max')"
                  >Must be less than
                  {{ universalForm.get(block.name).errors?.max.max }}
                </mat-error>
                <mat-error
                  *ngIf="universalForm.get(block.name).hasError('numberError')"
                >
                  {{ universalForm.get(block.name).errors.numberError }}
                </mat-error>
              </mat-form-field>
            </ng-container>

            <ng-container *ngIf="block.type === 'toggle'">
              <div class="flex flex-col mb-4">
                <div class="font-semibold text-sm mb-2">{{ block.label }}</div>
                <div>
                  <mat-slide-toggle
                    [color]="'primary'"
                    [formControlName]="block.name"
                  >
                    {{
                      universalForm.get(block.name).value === true
                        ? "Yes"
                        : "No"
                    }}
                  </mat-slide-toggle>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="block.type === 'select_enum'">
              <mat-form-field class="w-full">
                <mat-label class="font-medium text-sm">{{
                  block.label
                }}</mat-label>
                <mat-select [formControlName]="block.name" class="text-sm">
                  <mat-option
                    *ngFor="let item of block.optionSelectors"
                    [value]="item"
                  >
                    {{ item }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="universalForm.get(block.name).hasError('required')"
                >
                  Required
                </mat-error>
              </mat-form-field>
            </ng-container>

            <ng-container *ngIf="block.type === 'select_search'">
              <mat-form-field class="w-full">
                <mat-label class="font-medium text-sm">{{
                  block.label
                }}</mat-label>

                <mat-select
                  [formControlName]="block.name"
                  [disableOptionCentering]="false"
                  (selectionChange)="
                    onSelectedSearchValue(
                      block.optionSelectors,
                      universalForm,
                      block.name,
                      0,
                      block.searchSelectorMetas.optionValueKey,
                      $event.value
                    )
                  "
                >
                  <mat-select-trigger class="text-sm">
                    {{
                      getSelectedHuman(
                        block.optionSelectors,
                        block.searchSelectorMetas.optionValueKey,
                        universalForm.get(block.name).value,
                        block.searchSelectorMetas.optionDisplayKey
                      )
                    }}
                  </mat-select-trigger>
                  <div
                    class="flex items-center p-4 w-full gap-2 sticky top-0 z-10 bg-white"
                  >
                    <mat-icon
                      class="icon-size-5"
                      [svgIcon]="'heroicons_solid:search'"
                    ></mat-icon>
                    <div class="w-full">
                      <input
                        class="w-full text-sm"
                        matInput
                        [placeholder]="
                          block.searchSelectorMetas.searchBoxPlacehoder
                        "
                        #searchSelector
                        (keydown)="$event.stopPropagation()"
                      />
                    </div>
                  </div>
                  <mat-option
                    class="h-full leading-none py-2"
                    *ngFor="
                      let item of block.optionSelectors
                        | searchByKey
                          : block.searchSelectorMetas.searchByKey
                          : searchSelector.value;
                      trackBy: trackByFn
                    "
                    [value]="item[block.searchSelectorMetas.optionValueKey]"
                  >
                    <div class="flex flex-col h-full gap-2 text-sm">
                      <span>{{
                        item[block.searchSelectorMetas.optionDisplayKey]
                      }}</span>
                      <span class="text-sm text-black/50">{{
                        item[block.searchSelectorMetas.optionDisplayKey2]
                      }}</span>
                    </div>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </ng-container>

            <ng-container *ngIf="block.type === 'select_all'"> </ng-container>

            <ng-container *ngIf="block.type === 'trigger_list'">
              <div class="flex flex-col lg:flex-row w-full">
                <div class="flex flex-col lg:min-w-80">
                  <div class="">
                    <div class="flex items-center m-3 mr-2">
                      <div class="flex items-center">
                        <mat-icon
                          class="icon-size-5"
                          [svgIcon]="'heroicons_solid:search'"
                        ></mat-icon>
                        <div class="ml-2">
                          <input
                            class="w-full min-w-0 py-1 border-0"
                            type="text"
                            [placeholder]="block.searchSelectorMetas.searchBoxPlacehoder"
                            [maxLength]="30"
                            #newTriggered
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      class="flex flex-col max-h-64 py-2 border-t overflow-y-auto"
                    >
                      <ng-container
                        *ngFor="
                          let blockItem of block.optionSelectors
                            | searchByKey
                              : [block.searchSelectorMetas.searchByKey]
                              : newTriggered.value;
                          trackBy: trackByFn
                        "
                      >
                        <div
                          class="flex items-center h-10 min-h-10 px-4 cursor-pointer hover:bg-hover"
                          (click)="
                            onToggleTriggerItem(
                              universalForm,
                              block.name,
                              blockItem[
                                block.searchSelectorMetas.optionValueKey
                              ]
                            )
                          "
                          matRipple
                        >
                          <mat-checkbox
                            [checked]="
                              universalForm
                                .get(block.name)
                                .value.includes(
                                  blockItem[
                                    block.searchSelectorMetas.optionValueKey
                                  ]
                                )
                            "
                            class="flex items-center h-10 min-h-10 pointer-events-none"
                            [color]="'primary'"
                            [disableRipple]="true"
                          >
                          </mat-checkbox>

                          <div class="ml-1">
                            {{
                              blockItem[
                                block.searchSelectorMetas.optionDisplayKey
                              ]
                            }}
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>

                <div class="mx-6 w-full">
                  <ng-container
                    *ngIf="
                      universalForm.get(block.name).value &&
                        universalForm.get(block.name).value.length > 0;
                      else noTableDatas
                    "
                  >
                    <div class="flex flex-wrap items-center mx-1">
                      <ng-container
                        *ngFor="let item of universalForm.get(block.name).value"
                      >
                        <div
                          class="flex items-center m-1 py-0.5 px-3 rounded-full text-sm font-medium text-gray-500 bg-gray-100 dark:text-gray-300 dark:bg-gray-700"
                        >
                          <a
                            class="text-md font-medium whitespace-nowrap"
                            target="_blank"
                            >{{
                              getToggleTriggerItemDisplay(
                                block.optionSelectors,
                                block.searchSelectorMetas.optionValueKey,
                                block.searchSelectorMetas.optionDisplayKey,
                                item
                              )
                            }}</a
                          >
                          <mat-icon
                            class="ml-1 icon-size-4 cursor-pointer"
                            [svgIcon]="'heroicons_solid:x-circle'"
                            (click)="
                              onToggleTriggerItem(
                                universalForm,
                                block.name,
                                item
                              )
                            "
                          ></mat-icon>
                        </div>
                      </ng-container>
                    </div>
                  </ng-container>

                  <ng-template #noTableDatas>
                    <div
                      class="flex flex-auto flex-col items-center justify-center"
                    >
                      <mat-icon
                        class="icon-size-12"
                        [svgIcon]="'heroicons_outline:document'"
                      ></mat-icon>
                      <div
                        class="mt-4 font-semibold tracking-tight text-secondary"
                      >
                        There are no data!
                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="block.type === 'datepicker'">
              <!-- {{ universalForm.get(block.name).value }} -->
              <div>
                <mat-form-field
                  color="accent"
                  class="mx-2"
                  style="width: 150px"
                >
                  <mat-label class="font-semibold text-sm mb-2">{{
                    block.label
                  }}</mat-label>

                  <input
                    class="text-sm"
                    matInput
                    [matDatepicker]="picker1"
                    [formControlName]="block.name"
                  />
                  <mat-hint class="text-sm">MM/DD/YYYY</mat-hint>
                  <mat-datepicker-toggle
                    matIconSuffix
                    [for]="picker1"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>

                <mat-form-field color="accent" class="mx-1" style="width: 50px">
                  <mat-label class="font-semibold text-sm mb-2 mx-2"
                    >Hour</mat-label
                  >
                  <input
                    class="text-sm"
                    matInput
                    [formControlName]="block.name + '_hour'"
                    (change)="updateTime(block.name)"
                  />
                  <mat-hint class="text-sm">HH</mat-hint>
                </mat-form-field>

                <mat-form-field color="accent" style="width: 50px">
                  <mat-label class="font-semibold text-sm mb-2 mx-2"
                    >Minute</mat-label
                  >
                  <input
                    class="text-sm"
                    matInput
                    [formControlName]="block.name + '_minute'"
                    (change)="updateTime(block.name)"
                  />
                  <mat-hint class="text-sm">MM</mat-hint>
                </mat-form-field>
              </div>
            </ng-container>

            <ng-container *ngIf="block.type === 'select_infinity'">
              <mat-form-field class="w-full">
                <mat-label class="font-medium text-sm">
                  {{ block.label }} {{ blockPages[block.name] }}
                </mat-label>

                <mat-select
                  msInfiniteScroll
                  [formControlName]="block.name"
                  (infiniteScroll)="
                    onFetchInfinityScroll(
                      block.infinityScrollMetas.dataService,
                      block.name,
                      block.infinityScrollMetas,
                      null
                    )
                  "
                  (selectionChange)="
                    onSelectedInfinityScrollItem(
                      $event.value,
                      universalForm,
                      block.name,
                      block.infinityScrollMetas,
                      block.name
                    )
                  "
                  [disableOptionCentering]="false"
                  (closed)="
                    onCloseResetSearchInput(
                      block.infinityScrollMetas.dataService,
                      block.name,
                      block.infinityScrollMetas
                    )
                  "
                >
                  <mat-select-trigger class="text-sm">
                    {{
                      blockSelected[block.name]?.displayVal1 ||
                        blockSelected[block.name]?.displayVal2 ||
                        "Select Item"
                    }}
                  </mat-select-trigger>
                  <div
                    class="flex items-center p-4 w-full gap-2 sticky top-0 z-10 bg-white"
                  >
                    <mat-icon
                      class="icon-size-5"
                      [svgIcon]="'heroicons_solid:search'"
                    ></mat-icon>
                    <div class="w-full">
                      <input
                        class="w-full"
                        matInput
                        [formControl]="blockSearchInputs[block.name]"
                        placeholder="Search"
                        #newEnemy
                        (input)="
                          onSearchInfinityScroll(
                            block.infinityScrollMetas.dataService,
                            block.name
                          )
                        "
                        (keydown)="$event.stopPropagation()"
                      />
                    </div>
                  </div>

                  <mat-option
                    class="h-full leading-none py-2"
                    *ngFor="let option of blockDatas[block.name]"
                    [value]="option[block.infinityScrollMetas.optionValueKey]"
                  >
                    <div class="flex flex-col h-full gap-2">
                      <span>{{
                        option[block.infinityScrollMetas.optionDisplayKey]
                      }}</span>
                      <span class="text-sm text-black/50">{{
                        option[block.infinityScrollMetas.optionDisplayKey2]
                      }}</span>
                    </div>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </ng-container>
          </ng-template>
        </div>
      </div>
    </div>
  </div>

  <!-- Relation Table UI -->
  <div
    *ngIf="
      dynamicForm?.relationArrays.length > 0 &&
      dynamicForm.action !== DYNAMIC_FORM_ACTION.CREATE
    "
    class="mx-4 pt-4 rounded-2xl relation-box-bg"
  >
    <div
      *ngFor="let relation of dynamicForm?.relationArrays"
      [formArrayName]="relation.relationTable"
    >
      <div
        class="flex items-center justify-end w-full border-t px-4"
        *ngIf="relation.canAddNew === true"
      >
        <button
          class="ml-4"
          mat-button
          type="button"
          (click)="addNewFormGroupToRelationTable(relation.relationTable)"
        >
          Add {{ relation.title }}
        </button>
      </div>
      <div
        class="col-12 col-md-4 mb-2"
        *ngFor="
          let subFormGroup of universalForm.get(relation.relationTable)[
            'controls'
          ];
          let i = index
        "
      >
        <div *ngFor="let uiRow of relation?.relationUiRows" [formGroupName]="i">
          <div class="flex flex-row px-4">
            <div class="font-semibold">{{ uiRow.title }} # {{ i + 1 }}</div>
            <div style="margin-left: auto">
              <button
                mat-icon-button
                [color]="'warn'"
                (click)="deleteRelationTable(i, relation.relationTable)"
              >
                <mat-icon
                  class="icon-size-5"
                  [svgIcon]="'heroicons_solid:trash'"
                >
                </mat-icon>
              </button>
            </div>
          </div>
          <div class="flex flex-col sm:flex-row">
            <div class="flex flex-auto flex-wrap">
              <div
                class="flex flex-col w-full px-4"
                *ngFor="let block of uiRow.blockInputs"
                [ngClass]="[block.size]"
              >
                <ng-container
                  *ngIf="dynamicForm.readonly; else haveAccessOnFormSub"
                >
                  <div class="div w-full mb-4">
                    <div class="div-wrapper">
                      <div class="div-flex">
                        <div class="div-infix">
                          <mat-label class="font-medium text-sm">{{
                            block.label
                          }}</mat-label>
                        </div>
                      </div>
                      <div class="text-md text-secondary">
                        <span class>{{
                          subFormGroup.get(block.name).value
                        }}</span>
                      </div>
                    </div>
                  </div>
                </ng-container>

                <ng-template #haveAccessOnFormSub>
                  <ng-container
                    *ngIf="['text', 'number', 'password'].includes(block.type)"
                  >
                    <mat-form-field class="w-full">
                      <mat-label class="font-medium text-sm">{{
                        block.label
                      }}</mat-label>
                      <input
                        class="text-sm"
                        matInput
                        [formControlName]="block.name"
                        [type]="block.type"
                        [readonly]="
                          block.readonly ||
                          block.disableOnUpdate ||
                          block.disabledOnCreate
                        "
                      />
                    </mat-form-field>

                    <mat-error
                      *ngIf="subFormGroup.get(block.name).hasError('required')"
                    >
                      Required
                    </mat-error>
                    <mat-error
                      *ngIf="subFormGroup.get(block.name).hasError('trimError')"
                    >
                      {{ subFormGroup.get(block.name).errors.trimError }}
                    </mat-error>

                    <mat-error
                      *ngIf="subFormGroup.get(block.name).hasError('min')"
                      >Must be at least
                      {{ subFormGroup.get(block.name).errors?.min.min }}
                    </mat-error>

                    <mat-error
                      *ngIf="subFormGroup.get(block.name).hasError('max')"
                      >Must be less than
                      {{ subFormGroup.get(block.name).errors?.max.max }}
                    </mat-error>

                    <mat-error
                      *ngIf="
                        subFormGroup.get(block.name).hasError('numberError')
                      "
                    >
                      {{ subFormGroup.get(block.name).errors.numberError }}
                    </mat-error>
                  </ng-container>

                  <ng-container *ngIf="block.type === 'toggle'">
                    <div class="flex flex-col mb-4">
                      <div class="font-semibold text-sm mb-2">
                        {{ block.label }}
                      </div>
                      <div>
                        <mat-slide-toggle
                          [color]="'primary'"
                          [formControlName]="block.name"
                        >
                          {{
                            subFormGroup.get(block.name).value === true
                              ? "Yes"
                              : "No"
                          }}
                        </mat-slide-toggle>
                      </div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="block.type === 'select_enum'">
                    <mat-form-field class="w-full">
                      <mat-label class="font-medium text-sm">{{
                        block.label
                      }}</mat-label>
                      <mat-select
                        [formControlName]="block.name"
                        class="text-sm"
                      >
                        <mat-option
                          *ngFor="let item of block.optionSelectors"
                          [value]="item"
                        >
                          {{ item }}
                        </mat-option>
                      </mat-select>
                      <mat-error
                        *ngIf="
                          subFormGroup.get(block.name).hasError('required')
                        "
                      >
                        Required
                      </mat-error>
                    </mat-form-field>
                  </ng-container>

                  <ng-container *ngIf="block.type === 'select_infinity'">
                    <mat-form-field class="w-full">
                      <mat-label class="font-medium text-sm">
                        {{ block.label }}
                      </mat-label>

                      <mat-select
                        msInfiniteScroll
                        [formControlName]="block.name"
                        (infiniteScroll)="
                          onFetchInfinityScroll(
                            block.infinityScrollMetas.dataService,
                            block.name + '_' + i
                          )
                        "
                        [disableOptionCentering]="false"
                        (selectionChange)="
                          onSelectedInfinityScrollItem(
                            $event.value,
                            subFormGroup,
                            block.name,
                            block.infinityScrollMetas,
                            block.name + '_' + i
                          )
                        "
                      >
                        <mat-select-trigger class="text-sm">
                          {{
                            blockSelected[block.name + "_" + i]?.displayVal1 ||
                              blockSelected[block.name + "_" + i]
                                ?.displayVal2 ||
                              "Select Item"
                          }}
                        </mat-select-trigger>
                        <div
                          class="flex items-center p-4 w-full gap-2 sticky top-0 z-10 bg-white"
                        >
                          <mat-icon
                            class="icon-size-5"
                            [svgIcon]="'heroicons_solid:search'"
                          ></mat-icon>
                          <div class="w-full">
                            <input
                              class="w-full"
                              matInput
                              [formControl]="searchInputControl"
                              placeholder="Search"
                              #newEnemy
                              (input)="
                                onSearchInfinityScroll(
                                  block.infinityScrollMetas.dataService,
                                  block.name + '_' + i,
                                  block.infinityScrollMetas,
                                  null
                                )
                              "
                              (keydown)="$event.stopPropagation()"
                            />
                          </div>
                        </div>
                        <mat-option
                          class="h-full leading-none py-2"
                          *ngFor="
                            let option of blockDatas[block.name + '_' + i]
                          "
                          [value]="
                            option[block.infinityScrollMetas.optionValueKey]
                          "
                        >
                          <div class="flex flex-col h-full gap-2">
                            <span>{{
                              option[block.infinityScrollMetas.optionDisplayKey]
                            }}</span>
                            <span class="text-sm text-black/50">{{
                              option[
                                block.infinityScrollMetas.optionDisplayKey2
                              ]
                            }}</span>
                          </div>
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ng-container>
                </ng-template>
              </div>
            </div>
          </div>
          <!-- Divider -->
          <div class="my-1 border-t"></div>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="!dynamicForm.readonly"
    class="flex items-center justify-end w-full border-t px-8 py-4"
  >
    <button
      class="ml-4"
      mat-flat-button
      type="button"
      [color]="'primary'"
      [disabled]="!universalForm.valid || dynamicForm.readonly"
      (click)="submit()"
    >
      {{
        dynamicForm.action === DYNAMIC_FORM_ACTION.CREATE ? "Create" : "Update"
      }}
    </button>
  </div>
</form>
