import { UserType } from '../../modules/admin/apps/user-player/user-player.types';

export const ROLES = {
  ALL: [
    UserType.PLAYER,
    UserType.GAME_ADMIN,
    UserType.BACKEND_ADMIN,
    UserType.ROOT_ADMIN,
  ],
  All_BACKEND_USERS: [UserType.BACKEND_ADMIN, UserType.ROOT_ADMIN],
  All_GAME_USERS: [UserType.PLAYER, UserType.GAME_ADMIN],
  PLAYER: [UserType.PLAYER],
  GAME_ADMIN: [UserType.GAME_ADMIN],
  BACKEND_ADMIN: [UserType.BACKEND_ADMIN],
  ROOT_ADMIN: [UserType.ROOT_ADMIN],
};
