export const apiRoutes = {
  auth: {
    signin: 'auth/signin',
    signinByToken: 'auth/signin_token',
    refreshToken: 'auth/refresh_token',
  },
  upload: {
    file: 'upload',
  },
  newsletter: {
    getPagination: 'news-event',
    create: 'news-event',
    update: 'news-event',
  },
  userPlayer: {
    getPagination: 'user',
    create: 'user',
    update: 'user',
  },
  quest: {
    get: 'quest',
    getPagination: 'quest',
    create: 'quest',
    update: 'quest',
    delete: 'quest',
  },
  chatRoom: {
    get: 'chat',
    getPagination: 'chat',
    create: 'chat',
    update: 'chat',
  },
  msEnemy: {
    get: 'ms-enemy',
    getAll: 'ms-enemy',
    getPagination: 'ms-enemy',
    create: 'ms-enemy',
    update: 'ms-enemy',
  },
  msPetPart: {
    get: 'ms-pet-part',
    getPagination: 'ms-pet-part',
    create: 'ms-pet-part',
    update: 'ms-pet-part',
    delete: 'ms-pet-part',
  },
  msPetSkill: {
    get: 'ms-pet-skill',
    getPagination: 'ms-pet-skill',
    create: 'ms-pet-skill',
    update: 'ms-pet-skill',
    delete: 'ms-pet-skill',
  },
  msItemType: {
    get: 'ms-item-type',
    getPagination: 'ms-item-type',
    create: 'ms-item-type',
    update: 'ms-item-type',
  },
  msLevelPetStat: {
    get: 'ms-level-pet-stat',
    getPagination: 'ms-level-pet-stat',
    create: 'ms-level-pet-stat',
    update: 'ms-level-pet-stat',
    delete: 'ms-level-pet-stat',
    deleteAll: 'ms-level-pet-stat/remove/all',
  },
  mapArea: {
    get: 'ms-map-area',
    getPagination: 'ms-map-area',
    create: 'ms-map-area',
    update: 'ms-map-area',
    delete: 'ms-map-area',
  },
  mapAreaConfig: {
    get: 'map-area-config',
    getPagination: 'map-area-config',
    create: 'map-area-config',
    update: 'map-area-config',
    delete: 'map-area-config',
  },
  miniBossConfig: {
    get: 'mini-boss-config',
    getPagination: 'mini-boss-config',
    create: 'mini-boss-config',
    update: 'mini-boss-config',
    delete: 'mini-boss-config',
  },
  msWorldBossConfig: {
    get: 'msworld-boss-config',
    getPagination: 'msworld-boss-config',
    create: 'msworld-boss-config',
    update: 'msworld-boss-config',
    delete: 'msworld-boss-config',
    getWorldBossSchedules: 'msworld-boss-config/get-worldboss-schedules',
    getWorldBossEnemies: 'msworld-boss-config/get-worldboss-enemies',
    addWorldBossEnemies: 'msworld-boss-config/get-worldboss-enemies',
    deleteWorldBossEnemies: 'msworld-boss-config/delete-worldboss-enemies',
    addWorldBossSchedule: 'msworld-boss-config/add-worldboss-schedule',
    updateWorldBossSchedule: 'msworld-boss-config/update-worldboss-schedule',
    deleteWorldBossSchedule: 'msworld-boss-config/delete-worldboss-schedule',
  },
  mapLocation: {
    get: 'ms-map-location',
    getPagination: 'ms-map-location',
    create: 'ms-map-location',
    update: 'ms-map-location',
    delete: 'ms-map-location',
  },
  enemyWaveGroup: {
    get: 'ms-enemy-wave-group',
    getPagination: 'ms-enemy-wave-group',
    create: 'ms-enemy-wave-group',
    update: 'ms-enemy-wave-group',
    delete: 'ms-enemy-wave-group',
    getBattleReward: 'ms-enemy-wave-group/battle-reward',
    createBattleReward: 'ms-enemy-wave-group/battle-reward',
    updateBattleReward: 'ms-enemy-wave-group/battle-reward',
    deleteBattleReward: 'ms-enemy-wave-group/battle-reward',
    getEnemyWave: 'ms-enemy-wave-group/enemy-wave',
    createEnemyWave: 'ms-enemy-wave-group/enemy-wave',
    updateEnemyWave: 'ms-enemy-wave-group/enemy-wave',
    deleteEnemyWave: 'ms-enemy-wave-group/enemy-wave',
  },
  enemy: {
    get: 'enemy',
    getPagination: 'enemy',
    create: 'enemy',
    update: 'enemy',
    delete: 'enemy',
  },
  player: {
    get: 'user-player',
    getPagination: 'user-player',
    create: 'user-player',
    update: 'user-player',
    getPet: 'pets',
    updatePet: 'pets',
    updatePetTeam: 'pet-team',
    updatePassword: 'user-player/update-player-password',
    getPlayerItems: 'user-player/get-player-items',
    getPlayerQuests: 'user-player/get-player-quests',
    getPlayerAuditLogs: 'user-player/get-audit-logs',
    getPlayerPActionLogs: 'user-player/get-action-logs',
    getPlayerLoginLogs: 'user-player/get-login-logs',
    getPlayerMarketItems: 'user-player/get-player-market-items',
    updateOneMarketItem: 'user-player/update-player-market-item',
    updateOnePlayerQuest: 'user-player/update-player-quest',
    updateOnePlayerInventoryItem: 'user-player/update-player-inventory-item',
    updatePlayerMapStatistics: 'quest/updateMapStatistic',
    deletePlayerMapStatistics: 'quest/deleteMapStatistic',
    getMapStatisticsById: 'quest/getMapStatistic',
  },
  petNamePool: {
    get: 'pet-name-pool',
    getPagination: 'pet-name-pool',
    create: 'pet-name-pool',
    update: 'pet-name-pool',
    delete: 'pet-name-pool',
  },
  msPetSpecies: {
    get: 'ms-pet-species',
    getPagination: 'ms-pet-species',
    create: 'ms-pet-species',
    update: 'ms-pet-species',
    delete: 'ms-pet-species',
  },
  msPetPartRandomTable: {
    get: 'ms-pet-part-random-table',
    getPagination: 'ms-pet-part-random-table',
    create: 'ms-pet-part-random-table',
    update: 'ms-pet-part-random-table',
    delete: 'ms-pet-part-random-table',
  },
  msAvatarPartOption: {
    get: 'ms-avatar-part-option',
    getPagination: 'ms-avatar-part-option',
    create: 'ms-avatar-part-option',
    update: 'ms-avatar-part-option',
    delete: 'ms-avatar-part-option',
  },
  msGuildIconCat: {
    get: 'ms-guild-icon-cat',
    getPagination: 'ms-guild-icon-cat',
    create: 'ms-guild-icon-cat',
    update: 'ms-guild-icon-cat',
    delete: 'ms-guild-icon-cat',
  },
  msGuildIcon: {
    get: 'ms-guild-icon',
    getPagination: 'ms-guild-icon',
    create: 'ms-guild-icon',
    update: 'ms-guild-icon',
    delete: 'ms-guild-icon',
  },
  msGuildDungeon: {
    get: 'ms-guild-dungeon',
    getPagination: 'ms-guild-dungeon',
    create: 'ms-guild-dungeon',
    update: 'ms-guild-dungeon',
    delete: 'ms-guild-dungeon',
  },
  msPetEvoStepStats: {
    get: 'ms-pet-evo-step-stat',
    getPagination: 'ms-pet-evo-step-stat',
    create: 'ms-pet-evo-step-stat',
    update: 'ms-pet-evo-step-stat',
    delete: 'ms-pet-evo-step-stat',
  },
  msPetPartSkillPath: {
    get: 'ms-pet-part-skill-path',
    getPagination: 'ms-pet-part-skill-path',
    create: 'ms-pet-part-skill-path',
    update: 'ms-pet-part-skill-path',
    delete: 'ms-pet-part-skill-path',
  },
  msPetPartPassiveSkillPath: {
    get: 'ms-pet-part-passive-skill-path',
    getPagination: 'ms-pet-part-passive-skill-path',
    create: 'ms-pet-part-passive-skill-path',
    update: 'ms-pet-part-passive-skill-path',
    delete: 'ms-pet-part-passive-skill-path',
  },
  msPrivilege: {
    get: 'ms-privilege',
    getPagination: 'ms-privilege',
    create: 'ms-privilege',
    update: 'ms-privilege',
    delete: 'ms-privilege',
  },
  gameStatistics: {
    get: 'game-statistics',
  },
  gashapon: {
    get: 'ms-gashapon',
    getPagination: 'ms-gashapon',
    create: 'ms-gashapon',
    update: 'ms-gashapon',
    delete: 'ms-gashapon',
  },
  distributeItem: {
    create: 'mail/distribute-item',
  },
};
