import { map, Observable, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { UserService } from '../../user/user.service';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { User } from 'app/core/user/user.types';

@Injectable({ providedIn: 'root' })
export class RoleGuard implements CanActivate {
  user: User;
  constructor(private router: Router, private _userService: UserService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<any> {
    const expectedRoles = route.data.expectedRoles;
    return this._userService.get().pipe(
      map((user) => {
        if (
          !user ||
          !expectedRoles.some((role: string) => user.userType.includes(role))
        ) {
          this.router.navigate(['/apps/player']);
          return false;
        }

        return true;
      })
    );
  }
}
