import {
    AbstractControl,
    FormControl,
    ValidationErrors,
    ValidatorFn,
} from '@angular/forms';

export const CustomRequired: ValidatorFn = (
    control: AbstractControl
): ValidationErrors | null => {
    const value = control.value;

    if (!value) {
        return null;
    }

    return value.trim().length === 0 ? { required: true } : null;
};

export const TrimValidator: ValidatorFn = (
    control: FormControl
): ValidationErrors | null => {
    if (control.value?.startsWith(' ')) {
        return {
            trimError: 'Input has leading whitespace',
        };
    }
    if (control.value?.endsWith(' ')) {
        return {
            trimError: 'Input has trailing whitespace',
        };
    }

    return null;
};

export const NumberOnlyValidator: ValidatorFn = (
    control: FormControl
): ValidationErrors | null => {
    const value = control.value;

    if (value === null || value === undefined) {
        return {
            numberError: 'Input is not a valid number',
        };
    }

    if (value === '') {
        // Allow empty values
        return {
            numberError: 'Input is not a valid number',
        };
    }

    const isNumeric = !isNaN(parseFloat(value)) && isFinite(value);

    if (!isNumeric) {
        return {
            numberError: 'Input is not a valid number',
        };
    }

    return null;
};
