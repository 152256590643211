import { apiRoutes } from './api-route';
export const environment = {
  name: 'dev',
  production: false,
  baseApi: 'https://ygg-pet-api-dev.mayawizardgames.com',
  pathApi: apiRoutes,
  signInPage: {
    showSignup: false,
    showForgotPassword: false,
    showSocialSignin: false,
  },
};
